$opensans :'Open Sans', sans-serif;
$playfairDisplay:'Playfair Display', serif;
$lato:'Lato', sans-serif;
$roboto: 'Roboto', sans-serif;

$grey:#616e77;
$l-grey:#87919a;
$li-grey:#959ba0;
$black:#3d4348;
$grey1:#6b767f;
$b-green:#4b5e62;
$orange:#e4861f;
$bl-green:#1a6a7b;
$d-black:#192025;
$d-grey:#484848;
$red:#ca4c4c;
$dwhite:#eaedf0;
$dribble:#EA4C89;
$fb:#2E4DA7;
$tw:#00ACED;
$gplus:#CF3427;
$light-grey:#ccc;
$yellow:#fbb716;